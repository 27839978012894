.full-loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 5px solid rgba(204, 204, 204, 0.25);
  border-top-color: #fff;
  animation: spinner 0.6s linear infinite;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  background: transparent;
}

.full-loader:before > * {
  display: none !important;
}

.full-loader {
  width: 100vw;
  height: 100vh;
  background-image: var(--background-primary);
}

@keyframes spinner {
  to {
      transform: rotate(360deg);
  }
}
