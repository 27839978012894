.ion-page.login-page {
  background-image: var(--background-primary);

  ion-content {
    --background: transparent;
  }

  .logo {
    max-width: 320px;
  }

  .login-form {
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;
  }
}
